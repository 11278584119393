<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.contractId ? $t('common.form.title.editDocument')   + ' (' + this.payload.contractId + ')' : $t('common.form.title.createDocument')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col cols="7">
                        <b-row class="mb-2">
                            <b-col>
                                <zw-input-group v-model="form.title"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                                name="title"
                                />
                            </b-col>
                        </b-row>
                        <b-row v-if="hasProvider" class="mb-2">
                            <b-col>
                                <zw-switch-group v-model="form.use_provider_address"
                                                 name="use_provider_address"
                                                 :label-prefix="labelPrefix"
                                ></zw-switch-group>
                            </b-col>
                        </b-row>
                        <b-row class="mb-2">
                            <b-col>
                                <zw-ckeditor v-model="form.content"></zw-ckeditor>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6">
                                <b-button block
                                          variant="primary"
                                          @click="onSubmit()"
                                          class="mr-5"
                                >
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                            <b-col sm="6" class="text-sm-right text-xs-center">
                                <b-button block
                                          @click="reloadPreview()"
                                          class="mr-5"
                                >
                                    {{ $t('common.button.preview') }}
                                </b-button>
                            </b-col>
                        </b-row>

                    </b-col>
                    <b-col cols="5">
                        <iframe width="100%"
                                height="100%"
                                :style="[source ? {'min-height':'700px', 'border':'none'} : {'border': 'none'}]"
                                :src="source"
                        ></iframe>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'customer-document-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            previewFinishLoad: true,
            callback: null,
            source: null,
            hasProvider: false,
            form: {
                customer_id: null,
                content: null
            },
            labelPrefix: 'common.label.',
        }
    },
    mounted() {
    },
    methods: {
        ...mapGetters('Customer', ['getDocument', 'getCustomer']),
        // ...mapGetters('CustomerContract', ['getContractPreview', 'getContractAllFields']),
        reloadPreview() {
            let sendData = this.form
            sendData['preview'] = true
            sendData['customer_id'] = this.payload.customerId
            sendData['settingsPreview'] = true
            sendData = JSON.parse(JSON.stringify(sendData))
            if (this.previewFinishLoad) {
                this.load = true
                this.previewFinishLoad = false
                this.$store.dispatch('Customer/fetchDocumentPreview', sendData)
                    .then((result) => {
                        this.source = 'data:application/pdf;base64,' + result.data.content
                    })
                    .finally(() => {
                        this.previewFinishLoad = true
                        this.load = false
                    })
            }
        },
        shown() {
            this.loading = true
            const fetchCustomer = this.$store.dispatch('Customer/fetchCustomer', this.payload.customerId)

            let fetchArray = [fetchCustomer]
            if (this.payload.id) {
                const fetchDocument = this.$store.dispatch('Customer/fetchDocument', this.payload.id)
                fetchArray.push(fetchDocument)
            }

            Promise.all(fetchArray)
                .then(() => {
                    if (this.payload.id) {
                        this.form.id = this.getDocument().id
                        this.form.title = this.getDocument().title
                        this.form.content = this.getDocument().content
                        this.$refs['observer'].reset()
                    }

                    if(this.getCustomer().provider_id) {
                        this.hasProvider = true
                    }

                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.form.customer_id = this.payload.customerId
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('Customer/saveDocument', formData)
                        .then((response) => {
                            this.commonAfterSave(response, this.sidebar)
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false

                                this.$refs['observer'].setErrors(errors)
                            }
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>